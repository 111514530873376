import React from 'react';
import { Navigate } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import { instance } from '../../../axios/AxiosInstance';
import IStudyModel from '../../../models/study/IStudyModel';
import GlobalData from '../../../services/GlobalData';
import MyFormProps from '../interfaces/MyFormProps';
import StudyFormState from '../interfaces/StudyFormState';
import MyForm from '../MyForm';
import './StudyForm.scss';

export default class StudyForm<T extends IStudyModel, S extends StudyFormState<T>> extends MyForm<T, MyFormProps, S> {
    public getItemQueryParams = ""
    public entity = ""
    entityLevel = 0;

    getEntityPath() {
        if (this.entity.endsWith("s")) {
            return this.entity;
        } else {
            return this.entity + "s";
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
        this.setState({
            item: {
                ...this.state.item,
                [name]: value,
            }
        });

        // if (!this.state.editing && Utils.isNotEmpty(value)) {
        //     const searchParams = new URLSearchParams(window.location.search);
        //     searchParams.set(name, value);
        //     console.log(`Set param ${name}: ${value}`)
        //     window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
        // }
    };

    handleStateDropdownChange = (e: React.SyntheticEvent, { name, value }: any) => {
        this.setState(
            {
                ...this.state,
                [name]: value,
            }, () => {
                this.handleStateChange(name, value);
            }
        );
    };

    handleStateChange = (name: string, value: any) => {
        if (name === "lang") {
            this.getSectionsDropdownData(value);
        }
        else if (name === "section_id") {
            if (this.entityLevel > 1) {
                this.getUnitsDropdownData(value);
            }
        } else if (name === "unit_id") {
            if (this.entityLevel > 3) {
                this.getLessonsDropdownData(value);
            }
        }

        // only put actual id, not the default 0 value to the query params
        // if (!this.state.editing && value) {
        //     const searchParams = new URLSearchParams(window.location.search);
        //     searchParams.set(name, value);
        //     console.log(`Set param ${name}: ${value}`)
        //     window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
        // }
    }

    handleItemDropdownChange = (e: React.SyntheticEvent, { name, value }: any) => {
        this.setState({
            item: {
                ...this.state.item,
                [name]: value,
            }
        }, () => {
            this.handleStateChange(name, value);
        });
    };

    getItem() {
        this.setState({ loading: true });
        instance.get(`${this.getEntityPath()}?${this.getItemQueryParams}`)
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirectUrl: "/login" });
                } else if (res.status === 200) {
                    this.setState({ item: res.data.data, loading: false }, () => {
                        // get parent ids
                        if (this.entity === "lesson") {
                            this.getUnitItem(res.data.data.unit_id);
                        } else if (this.entity === "exercise") {
                            this.getLessonItem(res.data.data.lesson_id);
                        }
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false });
            });
    }

    getSectionItem(section_id: number) {
        console.log(`Getting section ${section_id} for ${this.entity} ${this.state.item.id}`);
        instance.get(`sections?id=${section_id}`)
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirectUrl: "/login" });
                } else if (res.status === 200) {
                    this.setState({
                        item: {
                            ...this.state.item,
                            lang: res.data.data.lang,
                        },
                        sections: [{
                            key: res.data.data.id,
                            value: res.data.data.id,
                            text: res.data.data.name
                        }],
                        section_id: res.data.data.id,
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ errorMsg: "Failed to get section data" });
            });
    }

    getUnitItem(unit_id: number) {
        console.log(`Getting unit ${unit_id} for ${this.entity} ${this.state.item.id}`);
        instance.get(`units?id=${unit_id}`)
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirectUrl: "/login" });
                } else if (res.status === 200) {
                    this.setState({
                        item: {
                            ...this.state.item,
                            section_id: res.data.data.section_id,
                        },
                        units: [{
                            key: res.data.data.id,
                            value: res.data.data.id,
                            text: res.data.data.name
                        }]
                    }, () => {
                        this.getSectionItem(res.data.data.section_id);
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ errorMsg: "Failed to get unit data" });
            });
    }

    getLessonItem(lesson_id: number) {
        console.log(`Getting lesson ${lesson_id} for ${this.entity} ${this.state.item.id}`);
        instance.get(`lessons?id=${lesson_id}`)
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirectUrl: "/login" });
                } else if (res.status === 200) {
                    this.setState({
                        item: {
                            ...this.state.item,
                            unit_id: res.data.data.unit_id,
                        },
                        lessons: [{
                            key: res.data.data.id,
                            value: res.data.data.id,
                            text: res.data.data.name
                        }]
                    }, () => {
                        this.getUnitItem(res.data.data.unit_id);
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ errorMsg: "Failed to get lesson item" });
            });
    }

    // applyQueryParams = () => {
    // }

    // getQueryParam = (param: string, defaultValue: number) => {
    //     const searchParams = new URLSearchParams(window.location.search);
    //     let value = searchParams.get(param);
    //     if (value) {
    //         return Number(value);
    //     } else {
    //         return defaultValue;
    //     }
    // }

    getSectionsDropdownData = (lang: string) => {
        GlobalData.getData("section", "lang=" + lang, true)
            .then(res => {
                const options = res.data.data.map((a: any) => ({
                    key: a.id,
                    value: a.id,
                    text: a.name,
                }));
                this.setState({ sections: options });
                // let section_id_query_param = this.getQueryParam("section_id", 0);
                // this.setState({ sections: dropdownOptions, section_id: section_id_query_param, units: [], unit_id: 0, lessons: [], lesson_id: 0 }, () => {
                //     if (section_id_query_param > 0 && this.entity === "unit") {
                //         this.setState({
                //             item: {
                //                 ...this.state.item,
                //                 section_id: section_id_query_param,
                //             }
                //         });
                //     }
                // });
                // need to trigger state change handler because on the above line, the state is changed programatically
                // this.handleStateChange("section_id", section_id_query_param);
            })
            .catch(err => {
                console.error(err);
            });
    }

    getUnitsDropdownData = (section_id: number) => {
        GlobalData.getData("unit", `section_id=${section_id}`, true)
            .then(res => {
                const options = res.data.data.map((a: any) => ({
                    key: a.id,
                    value: a.id,
                    text: a.name,
                }));
                // let unit_id_query_param = this.getQueryParam("unit_id", 0);
                this.setState({ units: options });
                // this.setState({ units: dropdownOptions, unit_id: unit_id_query_param, lessons: [], lesson_id: 0 }, () => {
                //     if (unit_id_query_param > 0 && this.entity === "lesson") {
                //         this.setState({
                //             item: {
                //                 ...this.state.item,
                //                 unit_id: unit_id_query_param,
                //             }
                //         });
                //     }
                // });
                // need to trigger state change handler because on the above line, the state is changed programatically
                // this.handleStateChange("unit_id", unit_id_query_param);
            })
            .catch(err => {
                console.error(err);
            });
    }

    getLessonsDropdownData = (unit_id: string) => {
        GlobalData.getData("lesson", "unit_id=" + unit_id, true)
            .then(res => {
                if (res.data.data) {
                    const options = res.data.data.map((a: any) => ({
                        key: a.id,
                        value: a.id,
                        text: a.name,
                    }));
                    // let lesson_id_query_param = this.getQueryParam("lesson_id", 0);
                    this.setState({ lessons: options });
                    // this.setState({ lessons: options, lesson_id: lesson_id_query_param }, () => {
                    //     if (lesson_id_query_param > 0 && this.entity === "exercise") {
                    //         this.setState({
                    //             item: {
                    //                 ...this.state.item,
                    //                 lesson_id: lesson_id_query_param,
                    //             }
                    //         });
                    //     }
                    // });
                    // need to trigger state change handler because on the above line, the state is changed programatically
                    // this.handleStateChange("lesson_id", lesson_id_query_param);
                }
            })
            .catch(err => {
                console.error(err);
                return undefined;
            });
    }

    validate = (): boolean => {
        return true;
    }

    save = () => {
        if (!this.validate()) {
            window.scrollTo(0, 0);
            return;
        }
        this.setState({ loading: true });
        if (this.state.editing) {
            this.put();
        } else {
            this.post();
        }
    }

    public post() {
        instance.post(this.getEntityPath(), this.state.item)
            .then((res: any) => {
                if (res.status === 200) {
                    this.setState({ errorMsg: "", successMsg: "Амжилттай хадгаллаа", loading: false });
                } else {
                    this.setState({ errorMsg: res.data.message, successMsg: "", loading: false });
                }
            })
            .catch(err => {
                console.error(err);
                if (err.status === 409) {
                    this.setState({ errorMsg: "Index already exists", successMsg: "", loading: false });
                } else {
                    this.setState({ errorMsg: err.message, successMsg: "", loading: false });
                }
            });
    }

    public put() {
        instance.put(this.getEntityPath(), this.state.item)
            .then((res: any) => {
                if (res.status === 200) {
                    this.setState({ errorMsg: "", successMsg: "Амжилттай хадгаллаа", loading: false });
                } else {
                    this.setState({ errorMsg: res.data.message, successMsg: "", loading: false });
                }
            })
            .catch(err => {
                console.error(err);
                if (err.status === 409) {
                    this.setState({ errorMsg: "Index already exists", successMsg: "", loading: false });
                } else {
                    this.setState({ errorMsg: err.message, successMsg: "", loading: false });
                }
            });
    }

    public deleteItem = () => {
        instance.delete(this.getEntityPath(), {
            data: this.state.item
        })
            .then(res => {
                console.log(res);
                if (res.status === 200) {

                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false, errorMsg: err.message });
            });
    }

    render() {
        if (this.state.redirectUrl) {
            <Navigate to={this.state.redirectUrl} replace={true} />
        }
        return (
            <React.Fragment></React.Fragment>
        );
    }
}