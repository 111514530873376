import { Navigate, useLocation } from 'react-router-dom';
import { instance } from '../../axios/AxiosInstance';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  //TODO apply time base logic
  var token = localStorage.getItem('biSurlaaToken')
  if (token && token !== 'undefined') {
    instance.post('admin/refresh')
      .then((res: any) => {
        console.log("Refreshed token")
        localStorage.setItem('biSurlaaToken', String(res.data.token))
      })
      .catch(err => {
        console.error(err)
        return <Navigate to="/login" state={{ from: location }} replace />;
      })
  } else {
    console.log("Need to login")
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;