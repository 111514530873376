import React from 'react';
import { IoIosCheckmarkCircleOutline, IoIosTrash } from "react-icons/io";
import { Link } from 'react-router-dom';
import { Button, Dimmer, Dropdown, Form, Loader, Table } from 'semantic-ui-react';
import GlobalData from '../../../services/GlobalData';
import StudyList from './StudyList';

export default class SectionList extends StudyList {
    entity = "section"

    constructor(props: any) {
        super(props);
        this.state = { lang: "mn", section_id: 0, unit_id: 0, lesson_id: 0, sections: [], units: [], lessons: [], loading: false, data: [], errorMsg: "", successMsg: "", redirectUrl: "", page: { page: 1, size: 15, totalPages: 1, totalRows: 0 } };
        this.queryParam = `?lang=mn`;
        this.getData();
    }

    render() {
        return (
            <section className="full-width">
            <h2>Section list</h2>

            <Dimmer active={this.state.loading} inverted>
                <Loader size='medium'></Loader>
            </Dimmer>

            <div>
                {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
            </div>

            <Form>
                <Form.Field className='flex-space-between'>
                    <h3>Filters:</h3>
                    <Link to="/sections/new">
                        <Button basic color='orange'>Create new</Button>
                    </Link>
                </Form.Field>
                <Form.Field>
                    <label>Language</label>
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        placeholder="Select Language"
                        fluid
                        selection
                        required
                        name="lang"
                        options={GlobalData.languageOptions}
                        value={this.state.lang}
                        onChange={this.onStateValueChange}
                    />
                </Form.Field>
            </Form>

            <br/>

            <Table striped width={16}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Language</Table.HeaderCell>
                        <Table.HeaderCell>Index</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.state?.data?.map((row: any, index: number) => {
                        return <Table.Row key={row.id}>
                            <Table.Cell>
                                <strong>
                                    <Link className="underline hover-blue" to={`/sections/${row.lang}/${row.index}`}>{row.id}</Link>
                                </strong>
                            </Table.Cell>
                            <Table.Cell>{String(row.lang)}</Table.Cell>
                            <Table.Cell>{String(row.index)}</Table.Cell>
                            <Table.Cell>{String(row.name)}</Table.Cell>
                            <Table.Cell>{String(row.description)}</Table.Cell>
                            <Table.Cell> {row.active ? <IoIosCheckmarkCircleOutline/> : null} </Table.Cell>
                            <Table.Cell>{String(row.version)}</Table.Cell>
                            <Table.Cell>
                                {row.confirmingDelete ?
                                    <React.Fragment>
                                        <Button primary size='mini' onClick={() => this.deleteItem(index)}>Delete</Button>
                                        <Button size='mini' onClick={() => this.toggleDelete(index)}>Cancel</Button>
                                    </React.Fragment> :
                                    <IoIosTrash className="cursor app-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                }
                            </Table.Cell>
                        </Table.Row>
                    })
                    }
                </Table.Body>
            </Table>
        </section>
        );
    };
}