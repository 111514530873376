import { useEffect, useState } from "react";
import { Button, Container, Dimmer, Dropdown, Form, Header, Loader, Modal, ModalContent } from "semantic-ui-react";
import { instance } from "../../../../axios/AxiosInstance";
import Utils from "../../../../helpers/Utils";
import Question from "../../../../models/problem/question/Question";
import ExerciseModel from "../../../../models/study/ExerciseModel";
import GlobalData from "../../../../services/GlobalData";
import './EditForm.scss';
import QuestionForm from "./QuestionForm";
import QuestionPreview from "./QuestionPreview";

function ExerciseEditForm() {
  const [showModal, setShowModal] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [loading, setLoading] = useState(true)
  const [successMsg, setSuccessMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [fullJsonText, setFullJsonText] = useState("")
  const [questionJsonText, setQuestionJsonText] = useState("")
  const [isJsonValid, setJsonValid] = useState(true)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [item, setItem] = useState<ExerciseModel>(new ExerciseModel(undefined, 0, 0, "", "", true, "v0"))
  const [newQuestion, setNewQuestion] = useState(new Question())

  useEffect(() => {
    const paths = window.location.pathname.split('/');
    const index = paths[3]
    const lessonId = paths[2]
    instance.get(`exercises?lesson_id=${lessonId}&index=${index}`)
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          setItem(res.data.data);
          setFullJsonText(JSON.stringify(res.data.data.questions, null, 2));
          setQuestionJsonText(JSON.stringify(res.data.data.questions[0], null, 2))
        }
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const onSubmit = () => {
    if (!confirmed) {
      setConfirmed(true)
    } else {
      setLoading(true)
      // set index
      for (let qi = 0; qi < item.questions.length; qi++) {
        const question = item.questions[qi]
        question.index = qi
      }
      put();
    }
  }

  const prevQuestion = () => {
    const index = currentQuestionIndex > 0 ? currentQuestionIndex - 1 : item.questions.length - 1
    setCurrentQuestionIndex(index)
    setQuestionJsonText(JSON.stringify(item.questions[index], null, 2))
  }

  const nextQuestion = () => {
    const index = currentQuestionIndex < item.questions.length - 1 ? currentQuestionIndex + 1 : 0
    setCurrentQuestionIndex(index)
    setQuestionJsonText(JSON.stringify(item.questions[index], null, 2))
  }

  const onJsonTextChange = (e: any) => {
    setCurrentQuestionIndex(0)
    if (!e.target.value) {
      setItem({ ...item, questions: [] })
      setFullJsonText("")
      setJsonValid(true)
      setErrorMsg("")
    }
    else {
      try {
        setFullJsonText(e.target.value)
        // try to parse the json
        const json = JSON.parse(e.target.value)
        setItem({ ...item, questions: json })
        setJsonValid(true)
        setErrorMsg("")
      }
      catch (e) {
        console.error(e)
        setJsonValid(false)
        setErrorMsg(String(e))
      }
    }
  }

  const onQuestionJsonTextChange = (e: any) => {
    if (!e.target.value) {
      setQuestionJsonText("")
    }
    else {
      try {
        setQuestionJsonText(e.target.value)
        // try to parse the json
        const json = JSON.parse(e.target.value)
        let tmpQuestions = item.questions
        tmpQuestions[currentQuestionIndex] = json;
        setItem({ ...item, questions: tmpQuestions })
        setFullJsonText(JSON.stringify(tmpQuestions, null, 2))
      }
      catch (e) {
        console.error(e)
      }
    }
  }

  const validateNewQuestion = (): boolean => {
    setErrorMsg("")
    if (Utils.isEmpty(newQuestion.question_mode)) {
      setErrorMsg("Enter question type.")
      return false;
    }
    if (Utils.isEmpty(newQuestion.answer_mode)) {
      setErrorMsg("Enter answer type.")
      return false;
    }
    if (Utils.isEmpty(newQuestion.index)) {
      setErrorMsg("Enter index.")
      return false;
    }
    if (newQuestion.question_mode !== "MATCH" && newQuestion.question_chunks.length === 0) {
      setErrorMsg("Enter question text.")
      return false;
    }
    if (newQuestion.answer_mode !== "TYPE" && newQuestion.options.length === 0) {
      setErrorMsg("Enter answer options.")
      return false;
    }
    if (newQuestion.question_mode !== "MATCH" && newQuestion.correct_answers.length === 0) {
      setErrorMsg("Enter correct answer.")
      return false;
    }
    setErrorMsg("")
    return true;
  }

  const addNewQuestion = () => {
    if (!validateNewQuestion()) {
      window.scrollTo(0, 0);
      return;
    }

    let tmpQuestions = item.questions
    tmpQuestions.push(newQuestion)

    setItem({
      ...item,
      questions: tmpQuestions
    });

    setNewQuestion({
      ...new Question(),
    })

    setFullJsonText(JSON.stringify(tmpQuestions, null, 2))
    setShowModal(false)
    put();
  }

  function deleteQuestion() {
    let tmpQuestions = item.questions
    const indexToDelete = currentQuestionIndex
    if (currentQuestionIndex === item.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
    tmpQuestions.splice(indexToDelete, 1)
    setItem({ ...item, questions: tmpQuestions })
    setFullJsonText(JSON.stringify(item.questions, null, 2))
  }

  function moveDown() {
    let tmpQuestions = item.questions
    const indexToMove = currentQuestionIndex
    if (indexToMove > 0) {
      const tmp = tmpQuestions[indexToMove]
      tmpQuestions[indexToMove] = tmpQuestions[indexToMove - 1]
      tmpQuestions[indexToMove - 1] = tmp
      setItem({ ...item, questions: tmpQuestions })
      setFullJsonText(JSON.stringify(item.questions, null, 2))
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
  }

  function moveUp() {
    let tmpQuestions = item.questions
    const indexToMove = currentQuestionIndex
    if (indexToMove < item.questions.length - 1) {
      const tmp = tmpQuestions[indexToMove]
      tmpQuestions[indexToMove] = tmpQuestions[indexToMove + 1]
      tmpQuestions[indexToMove + 1] = tmp
      setItem({ ...item, questions: tmpQuestions })
      setFullJsonText(JSON.stringify(item.questions, null, 2))
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  function put() {
    instance.put("/exercises", item)
      .then((res: any) => {
        setLoading(false);
        if (res.status === 200) {
          setSuccessMsg("Saved successfully");
          setErrorMsg("");
        } else {
          setSuccessMsg("")
          setErrorMsg("Error: " + res.data.message);
        }
      })
      .catch(err => {
        console.error(err);
        setSuccessMsg("")
        setErrorMsg(err.message);
      });
  }

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader size='medium'></Loader>
      </Dimmer>

      <h2>Update exercise</h2>

      <Form onSubmit={() => onSubmit()}>
        <Form.Group>
          {successMsg ? <div className="success-msg">{successMsg}</div> : null}
          {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
        </Form.Group>

        {/* <Form.Field>
          <label>Language</label>
        </Form.Field> */}
        {/* <Form.Field>
          <Dropdown
            placeholder="Select Language"
            fluid
            selection
            required
            disabled
            name="lang"
            options={GlobalData.languageOptions}
            value={lang}
            // onChange={this.handleStateDropdownChange}
          />
        </Form.Field> */}

        {/* <Form.Field>
          <label>Section</label>
        </Form.Field> */}
        {/* <Form.Field>
          <Dropdown
            placeholder="Select section"
            fluid
            selection
            search
            required
            disabled={editing}
            name="section_id"
            options={sections}
            value={section_id}
            // onChange={this.handleStateDropdownChange}
          />
        </Form.Field> */}

        {/* <Form.Field>
          <label>Unit</label>
        </Form.Field> */}
        {/* <Form.Field>
          <Dropdown
            placeholder="Select unit"
            fluid
            selection
            search
            required
            disabled={editing}
            name="unit_id"
            options={units}
            value={unit_id}
            onChange={this.handleStateDropdownChange}
          />
        </Form.Field> */}

        <Form.Field>
          <label>Lesson ID: {item.lesson_id}</label>
        </Form.Field>
        {/* <Form.Field>
          <Dropdown
            placeholder="Select lesson"
            fluid
            selection
            search
            required
            disabled={editing}
            name="lesson_id"
            options={lessons}
            value={item.lesson_id}
            onChange={this.handleItemDropdownChange}
          />
        </Form.Field> */}

        <Form.Field>
          <label>Index: {item.index}</label>
        </Form.Field>

        <Form.Field>
          <label>Name</label>
          <input
            type="text"
            name="name"
            required
            value={item.name}
            onChange={(e) => { setItem({ ...item, name: e.target.value }) }}
            placeholder="Enter name"
          />
        </Form.Field>

        <Form.Field>
          <label>Description</label>
          <input
            type="text"
            name="description"
            required
            value={item.description}
            onChange={(e) => { setItem({ ...item, description: e.target.value }) }}
            placeholder="Enter description"
          />
        </Form.Field>

        <Form.Field>
          <label>Active</label>
          <input
            type="checkbox"
            name="active"
            checked={item.active}
          // onChange={(e) => this.handleChange(e, { name: 'active', value: e.target.checked })}
          />
        </Form.Field>

        <Form.Field>
          <label>Version</label>
          <Dropdown
            placeholder="Select a version"
            fluid
            selection
            required
            disabled
            name="version"
            options={GlobalData.versionOptions}
            value={item.version}
          // onChange={this.handleItemDropdownChange}
          />
        </Form.Field>

        <br />

        <Form.Field>
          <label>QUESTIONS JSON:</label>
          {isJsonValid ? null : <p style={{ color: "red" }}>JSON is invalid</p>}
          <textarea name="jsonText"  value={fullJsonText}  onChange={(e) => onJsonTextChange(e)}/>
        </Form.Field>

        <Form.Field>
          <Button type="submit" primary>{confirmed ? "Update" : "Verify"} exercise</Button>
        </Form.Field>

        <br/>
      </Form>

      <Button onClick={() => setShowModal(true)}>Add a new question</Button>

      {showModal ?
        <Modal closeOnDimmerClick={false} closeIcon basic dimmer="inverted" onClose={() => setShowModal(false)} open={showModal} size="large">
          <Header/>
          <ModalContent scrolling className="modal-content" >
            <QuestionForm newQuestion={newQuestion} onChange={(val: any) => setNewQuestion(val)} />
            <Form.Field>
              <br></br>
              <Form.Group>
              {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
              </Form.Group>
              <Button onClick={() => addNewQuestion()}>Save new question</Button>
            </Form.Field>
          </ModalContent>
        </Modal>
        : null
      }

      <hr />
      <h2>Preview questions:</h2>
      <section id="preview">
        <div className="control-line">
          <Button disabled={currentQuestionIndex === 0} onClick={moveDown}>Move down</Button>
          <Button onClick={deleteQuestion}>Delete</Button>
          <Button disabled={currentQuestionIndex === item.questions.length - 1} onClick={moveUp}>Move up</Button>
        </div>
        <div className="control-line">
          <button className="control" onClick={() => prevQuestion()}>Prev</button>
          <span>&nbsp;{currentQuestionIndex + 1} of {item?.questions?.length}&nbsp;</span>
          <button className="control" onClick={() => nextQuestion()}>Next</button>
        </div>
        <div className="preview-body">
          <QuestionPreview question={item?.questions[currentQuestionIndex]} />
          <div className="preview-col">
            <textarea className="json" name="questionJson"  value={questionJsonText}  onChange={(e) => onQuestionJsonTextChange(e)}/>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default ExerciseEditForm;