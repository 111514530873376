export default class Utils {

  public static generateId() {
    return Date.now();
  }

  public static isEmpty(value: any) {
    return value === undefined || value === null || value === '';
  }

  public static isNotEmpty(value: any) {
    return value !== undefined && value !== null && value !== '';
  }

  public static shuffle(array: any[]) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  public static containsMongolianCryllic(text: string) {
    return (/[\u0400-\u04FF\u1800-\u18AF]/.test(text) || /[өүӨҮ]/.test(text))
  }

  public static isMongolian(text: string) {
    return /[\u0400-\u04FF\u1800-\u18AF]/.test(text) || /[өүӨҮ]/.test(text)
  }
}