import { Button, Container, Dimmer, Dropdown, Form, Header, Loader, Modal, ModalContent } from 'semantic-ui-react';
import Utils from '../../../helpers/Utils';
import Question from '../../../models/problem/question/Question';
import ExerciseModel from '../../../models/study/ExerciseModel';
import IStudyModel from '../../../models/study/IStudyModel';
import GlobalData from '../../../services/GlobalData';
import StudyFormState from '../interfaces/StudyFormState';
import QuestionForm from './exercise/QuestionForm';
import QuestionPreview from './exercise/QuestionPreview';
import StudyForm from './StudyForm';

interface QuestionsFormState<T extends IStudyModel> extends StudyFormState<T> {
    currentQuestionIndex: number;
    isJsonValid: boolean;
    questionsJsonText: string;
    questionJsonText: string;
    verifiedItem: boolean;
    newQuestion: Question;
    showModal: boolean;
}

export default class ExerciseForm extends StudyForm<ExerciseModel, QuestionsFormState<ExerciseModel>> {
    entity = 'exercise'
    queryParam = "?lesson_id=0"
    entityLevel = 4;
    bucketUrl = "https://lingoassets.s3.ap-northeast-2.amazonaws.com/";

    constructor(props: any) {
        super(props);
        this.state = { showModal: false, currentQuestionIndex: 0, isJsonValid: true, questionsJsonText: "", questionJsonText: "", verifiedItem: false, newQuestion: new Question(), lang: "mn", section_id: 0, unit_id: 0, lesson_id: 0, sections: [], units: [], lessons: [], loading: false, editing: false, item: {} as ExerciseModel, successMsg: "", errorMsg: "", redirectUrl: "" };
    }

    componentDidMount(): void {
        this.getSectionsDropdownData("mn");

        const paths = window.location.pathname.split('/');
        if (paths[2] === "new") {
            this.setState({ item: new ExerciseModel(undefined, 0, 0, "", "", true, "v0") });
        } else {
            this.setState({ editing: true });
            this.getItemQueryParams = `lesson_id=${paths[2]}&index=${paths[3]}`;
            this.getItem();
        }
    }

    validate = (): boolean => {
        this.setState({ errorMsg: "" });
        if (Utils.isEmpty(this.state.item.index)) {
            this.setState({ errorMsg: "Enter index please. This will be the placement of this questions in the parent lesson." });
            return false;
        }
        if (Utils.isEmpty(this.state.item.name)) {
            this.setState({ errorMsg: "Enter a name for these questions please." });
            return false;
        }
        if (Utils.isEmpty(this.state.item.description)) {
            this.setState({ errorMsg: "Enter description for these questions please." });
            return false;
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    validateNewQuestion = (): boolean => {
        this.setState({ errorMsg: "" });
        if (Utils.isEmpty(this.state.newQuestion.question_mode)) {
            this.setState({ errorMsg: "Enter question type." });
            return false;
        }
        if (Utils.isEmpty(this.state.newQuestion.answer_mode)) {
            this.setState({ errorMsg: "Enter answer type." });
            return false;
        }
        if (Utils.isEmpty(this.state.newQuestion.index)) {
            this.setState({ errorMsg: "Enter index." });
            return false;
        }
        if (this.state.newQuestion.question_mode !== "MATCH" && this.state.newQuestion.question_chunks.length === 0) {
            this.setState({ errorMsg: "Enter question text." });
            return false;
        }
        if (this.state.newQuestion.answer_mode !== "TYPE" && this.state.newQuestion.options.length === 0) {
            this.setState({ errorMsg: "Enter answer options." });
            return false;
        }
        if (this.state.newQuestion.question_mode !== "MATCH" && this.state.newQuestion.correct_answers.length === 0) {
            this.setState({ errorMsg: "Enter correct answer." });
            return false;
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    addNewQuestion = () => {
        if (!this.validateNewQuestion()) {
            window.scrollTo(0, 0);
            return;
        }

        this.setState({
            item: {
                ...this.state.item,
                questions: [...this.state.item.questions || [], this.state.newQuestion]
            },
            newQuestion: {
                ...this.state.newQuestion,
                index: this.state.newQuestion.index + 1,
                question_chunks: [],
                options: [],
                correct_answers: [],
                graphic: "",
                character: "",
            },
            showModal: false
        }, () => {
            this.setState({ questionsJsonText: JSON.stringify(this.state.item.questions, null, 2) })
        });
    }

    onSubmit() {
        if (this.state.verifiedItem) {
            this.save();
        }
        else {
            this.setState({ verifiedItem: true })
        }
    }

    onJsonTextChange = (e: any) => {
        this.setState({ currentQuestionIndex: 0 })
        if (!e.target.value) {
            this.setState({
                item: {
                    ...this.state.item,
                    questions: []
                },
                questionsJsonText: "",
                isJsonValid: true,
                errorMsg: ""
            })
        }
        else {
            try {
                this.setState({
                    questionsJsonText: e.target.value
                })
                // try to parse the json
                const json = JSON.parse(e.target.value)
                this.setState({
                    item: {
                        ...this.state.item,
                        questions: json
                    },
                    isJsonValid: true,
                    errorMsg: ""
                })
            }
            catch (e) {
                this.setState({
                    isJsonValid: false,
                    errorMsg: String(e)
                })
            }
        }
    }

    prevQuestion = () => {
        if (this.state.currentQuestionIndex > 0) {
            this.setState({ currentQuestionIndex: this.state.currentQuestionIndex - 1 })
        }
    }

    nextQuestion = () => {
        if (this.state.currentQuestionIndex < this.state.item.questions.length - 1) {
            this.setState({ currentQuestionIndex: this.state.currentQuestionIndex + 1 })
        }
    }

    onQuestionJsonTextChange(e: any) {
        if (!e.target.value) {
          this.setState({ questionJsonText: "" })
        }
        else {
          try {
            this.setState({ questionJsonText: e.target.value })
            // try to parse the json
            const json = JSON.parse(e.target.value)
            let tmpQuestions = this.state.item.questions
            tmpQuestions[this.state.currentQuestionIndex] = json;
            this.setState({ item: {...this.state.item, questions: tmpQuestions }, questionsJsonText: JSON.stringify(tmpQuestions, null, 2)})
          }
          catch (e) {
            console.error(e)
          }
        }
    }

    render() {
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>

                <h2>{!this.state.editing ? 'New' : ''} Exercise</h2>

                <Form onSubmit={() => this.onSubmit()}>
                    <Form.Group>
                        {this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null}
                        {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
                    </Form.Group>

                    <Form.Field>
                        <label>Language</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select Language"
                            fluid
                            selection
                            required
                            disabled={this.state.editing}
                            name="lang"
                            options={GlobalData.languageOptions}
                            value={this.state.lang}
                            onChange={this.handleStateDropdownChange}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Section</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select section"
                            fluid
                            selection
                            search
                            required
                            disabled={this.state.editing}
                            name="section_id"
                            options={this.state.sections}
                            value={this.state.section_id}
                            onChange={this.handleStateDropdownChange}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Unit</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select unit"
                            fluid
                            selection
                            search
                            required
                            disabled={this.state.editing}
                            name="unit_id"
                            options={this.state.units}
                            value={this.state.unit_id}
                            onChange={this.handleStateDropdownChange}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Lesson</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select lesson"
                            fluid
                            selection
                            search
                            required
                            disabled={this.state.editing}
                            name="lesson_id"
                            options={this.state.lessons}
                            value={this.state.item.lesson_id}
                            onChange={this.handleItemDropdownChange}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Index</label>
                        <input
                            type="number"
                            name="index"
                            required
                            disabled={this.state.editing}
                            value={this.state.item.index}
                            onChange={(e) => this.handleChange(e, { name: 'index', value: Number(e.target.value) })}
                            min={0}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            required
                            value={this.state.item.name}
                            onChange={(e) => this.handleChange(e, { name: 'name', value: e.target.value })}
                            placeholder="Enter name"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            required
                            value={this.state.item.description}
                            onChange={(e) => this.handleChange(e, { name: 'description', value: e.target.value })}
                            placeholder="Enter description"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Active</label>
                        <input
                            type="checkbox"
                            name="active"
                            checked={this.state.item.active}
                            onChange={(e) => this.handleChange(e, { name: 'active', value: e.target.checked })}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Version</label>
                        <Dropdown
                            placeholder="Select a version"
                            fluid
                            selection
                            required
                            disabled
                            name="version"
                            options={GlobalData.versionOptions}
                            value={this.state.item.version}
                            onChange={this.handleItemDropdownChange}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>QUESTIONS JSON:</label>
                        {this.state.isJsonValid ? null : <p style={{ color: "red" }}>JSON is invalid</p>}
                        <textarea
                            name="questionsJsonText"
                            value={this.state.questionsJsonText}
                            onChange={(e) => this.onJsonTextChange(e)}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Button type="submit" primary>{this.state.editing ? "Update" : this.state.verifiedItem ? "Create" : "Verify"} exercise</Button>
                    </Form.Field>
                </Form>
                <hr />

                <br />
                <br />
                <br />
                <br />

                {/* QUESTIONS */}
                {/* <h2>Questions</h2> */}
                {/* {this.state.item.questions?.length > 0 ?
                    <Table striped width={16}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Index</Table.HeaderCell>
                                <Table.HeaderCell>Question type</Table.HeaderCell>
                                <Table.HeaderCell>Answer type</Table.HeaderCell>
                                <Table.HeaderCell>Question text</Table.HeaderCell>
                                <Table.HeaderCell>Options</Table.HeaderCell>
                                <Table.HeaderCell>Correct Answers</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.item.questions?.map((question: Question, index: number) => {
                                return <TableRow key={index}>
                                    <TableCell>{question.index}</TableCell>
                                    <TableCell>{question.question_mode}</TableCell>
                                    <TableCell>{question.answer_mode}</TableCell>
                                    <TableCell>{question.question_chunks?.map(q => q.text).join(" ")}</TableCell>
                                    <TableCell>{question.options?.map(o => o.text).join(" ")}</TableCell>
                                    <TableCell>{question.correct_answers?.join(" ")}</TableCell>
                                </TableRow>
                            })
                            }
                        </Table.Body>
                    </Table> : null} */}

                <Button onClick={() => this.setState({showModal: true})}>Add a new question</Button>

                    <Modal closeOnDimmerClick={false} closeIcon basic dimmer="inverted" onClose={() => this.setState({ showModal: false })} open={this.state.showModal} size="large">
                        <Header/>
                        <ModalContent className="modal-content" >
                            <QuestionForm newQuestion={this.state.newQuestion} onChange={(val: any) => this.setState({ newQuestion: val })} />
                            <br />
                            <Form.Group>
                                {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
                                <Button primary onClick={this.addNewQuestion}>Add a new question</Button>
                            </Form.Group>
                        </ModalContent>
                    </Modal>
                <br />
                <br />
                <br />
                
                <section id="preview">
                    { this.state.item?.questions?.length > 0 ?
                    <>
                        <h2>Preview:</h2>
                        <div className="control-line">
                            {/* <Button disabled={currentQuestionIndex === 0} onClick={moveDown}>Move down</Button>
                            <Button onClick={deleteQuestion}>Delete</Button>
                            <Button disabled={currentQuestionIndex === item.questions.length - 1} onClick={moveUp}>Move up</Button> */}
                        </div>
                        <div className="control-line">
                            <button className="control" onClick={() => this.prevQuestion()}>Prev</button>
                            <span>&nbsp;{this.state.currentQuestionIndex + 1} of {this.state.item?.questions?.length}&nbsp;</span>
                            <button className="control" onClick={() => this.nextQuestion()}>Next</button>
                        </div>
                        {this.state.item?.questions?.length > 0 ?
                            <div className="preview-body">
                                <QuestionPreview question={this.state.item?.questions[this.state.currentQuestionIndex]} />
                                <div className="preview-col">
                                    <textarea className="json" name="questionJson"  value={this.state.questionJsonText}  onChange={(e) => this.onQuestionJsonTextChange(e)}/>
                                </div>
                            </div> : null}
                    </> : null}
                </section>
                <br />
                <br />
                <br />
                <br />
            </Container>
        );
    }
}
