import React from 'react';
import { Navigate } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import { instance } from '../../axios/AxiosInstance';
import Utils from '../../helpers/Utils';
import LocalStore from '../../services/LocalStore';
import MyListProps from './interfaces/MyListProps';
import MyListState from './interfaces/MyListState';

export default class MyList<P extends MyListProps, S extends MyListState> extends React.Component<P, S> {
    entity = ""
    queryParam = ""

    componentDidMount() {
        this.getData();
    }
    //TODO save filter selected values in the url query params

    getData() {
        if (Utils.isEmpty(this.queryParam)) {
            return;
        }
        this.setState({ loading: true });
        let url = `${this.entity}s` + (this.queryParam ? this.queryParam : "")

        instance.get(url)
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirectUrl: "/login" });
                } else {
                    if (res.data?.data?.length > 0) {
                        LocalStore.saveData(url, res.data);
                        console.log(`Saved ${res.data?.data?.length} data for ${url}`);
                        
                    }
                    let data: any[] = res.data.data;
                    data.forEach(b => b.confirmingDelete = false);
                    this.setState({ loading: false, data: data });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false });
            });
    }

    handleDropdownChange = (e: React.SyntheticEvent, { name, value }: any) => {
        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    // onPageChange(event: any, data: any) {
    //     this.setState({ page: Object.assign(this.state.page, { page: data.activePage }) }, () => {
    //         this.getData()
    //         window.scrollTo(0, 100)
    //     });
    // }

    public toggleDelete = (index: number) => {
        let data = this.state.data;
        data[index].confirmingDelete = !data[index].confirmingDelete;
        this.setState({ data: data });
    }

    public deleteItem = (index: number) => {
        if (!this.state.data[index].id) {
            return;
        }
        let toDelete = this.state.data[index];
        instance.delete(`${this.entity}s`, {
            data: toDelete
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({ errorMsg: "" });
                    this.getData();
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false, errorMsg: err.message });
            });
    }

    render() {
        if (this.state.redirectUrl) {
            <Navigate to={this.state.redirectUrl} replace={true} />
        }
        return (
            <React.Fragment></React.Fragment>
        );
    }
}