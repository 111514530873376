import { instance } from "../axios/AxiosInstance";
import LocalStore from "./LocalStore";

export default class GlobalData {
  public static languageOptions = [
    { key: 'mn', text: 'Монгол', value: 'mn' },
    { key: 'en', text: 'English', value: 'en' },
  ];

  public static versionOptions = [
    { key: 'v0', text: 'v0', value: 'v0' }
  ];

  public static QuestionModes = [
    "TRANSLATE",
    "TRANSLATE_SENTENCE",
    "LISTEN",
    "MATCH",
    "FILL_IN",
  ].map(e => ({ key: e, text: e, value: e }));

  public static AnswerModesMap = {
    "TRANSLATE": [
      "CHOOSE_OPTION",
      "TYPE"
    ],
    "TRANSLATE_SENTENCE": ["SELECT_WORDS"],
    "LISTEN": [
      "CHOOSE_OPTION",
      "TYPE"
    ],
    "MATCH": ["MATCH"],
    "FILL_IN": [
      "CHOOSE_OPTION",
      "TYPE"
    ]
  };

  public static QuestionChunkTypes = [
    "TEXT",
    "TEXT_AUDIO"
  ].map(e => ({ key: e, text: e, value: e }));

  public static OptionTypesMap = {
    "TYPE": [],
    "CHOOSE_OPTION": [
      "TEXT",
      "TEXT_AUDIO",
      "AUDIO",
      "GRAPHIC"
    ],
    "SELECT_WORDS": ["TEXT_AUDIO","TEXT"],
    "MATCH": ["TEXT_AUDIO"]
  };

  public static CharacterOptions = [
    "",
    "Bear",
    "Misheel",
    "Bold",
    "Nara",
    "Jack"
  ].map(e => ({ key: e, text: e, value: e }));

  public static async getData(entity: string, queryParam: string, needFreshData = false): Promise<any> {
    try {
      let url = `${entity}s${(queryParam ? ("?" + queryParam) : "")}`;
      
      console.debug("Datafeed: Need to fetch from ", url);
        if (!needFreshData) {
        const localData = LocalStore.getData(url);
        
        if (localData) {
          console.debug(`Datafeed: Returning ${localData.data.length} local data for ${url}`);
          return Promise.resolve({ status: 200, data: localData });
        }
      }
      
      console.debug("Datafeed: Fetching live data for ", url);
      
      const response = await instance.get(url);
      if (response.status === 200) {
        if (response.data?.data?.length > 0) {
          LocalStore.saveData(url, response.data);
          console.debug(`Datafeed: Saved ${response.data?.data?.length} data for ${url}`);
        }
        
        return Promise.resolve(response);
      }

      return Promise.reject(response);
    }
    catch (error) {
      return Promise.reject(error);
    }
  }
}