import axios from "axios";

export const instance = axios.create({
  baseURL: "https://eca9xcm0d0.execute-api.ap-northeast-2.amazonaws.com/Prod/",
  headers: {
    'Accept': 'application/json',
    // 'Access-Control-Allow-Origin' : '*',
    'Authorization': `Bearer ${localStorage.getItem('biSurlaaToken')}`,
    "Content-type": "application/json; charset=UTF-8",
  },
});