import IOption from "./IOption"

export default class GraphicOption implements IOption {
  type = 'GRAPHIC'
  text: string
  graphic: string

  constructor(text: string, graphic: string) {
    this.text = text
    this.graphic = graphic
  }
}