import React from 'react';
import { Navigate } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import { instance } from '../../axios/AxiosInstance';
import IModel from '../../models/IModel';
import MyFormProps from './interfaces/MyFormProps';
import MyFormState from './interfaces/MyFormState';

export default class MyForm<T extends IModel, P extends MyFormProps, S extends MyFormState<T>> extends React.Component<P, S> {
    public getItemQueryParams = ""
    public entity = ""

    getEntityPath() {
        if (this.entity.endsWith("s")) {
            return this.entity;
        } else {
            return this.entity + "s";
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
        this.setState({
            item: {
                ...this.state.item,
                [name]: value,
            }
        });
    };

    validate = (): boolean => {
        return true;
    }

    save = () => {
        if (!this.validate()) {
            window.scrollTo(0, 0);
            return;
        }
        this.setState({ loading: true });
        if (this.state.editing) {
            this.put();
        } else {
            this.post();
        }
    }

    public post() {
        instance.post(this.getEntityPath(), this.state.item)
            .then((res: any) => {
                if (res.status === 200) {
                    this.setState({ errorMsg: "", successMsg: "Амжилттай хадгаллаа", loading: false });
                } else {
                    this.setState({ errorMsg: res.data.message, successMsg: "", loading: false });
                }
            })
            .catch(err => {
                console.error(err);
                if (err.status === 409) {
                    this.setState({ errorMsg: "Index already exists", successMsg: "", loading: false });
                } else {
                    this.setState({ errorMsg: err.message, successMsg: "", loading: false });
                }
            });
    }

    public put() {
        instance.put(this.getEntityPath(), this.state.item)
            .then((res: any) => {
                if (res.status === 200) {
                    this.setState({ errorMsg: "", successMsg: "Амжилттай хадгаллаа", loading: false });
                } else {
                    this.setState({ errorMsg: res.data.message, successMsg: "", loading: false });
                }
            })
            .catch(err => {
                console.error(err);
                if (err.status === 409) {
                    this.setState({ errorMsg: "Index already exists", successMsg: "", loading: false });
                } else {
                    this.setState({ errorMsg: err.message, successMsg: "", loading: false });
                }
            });
    }

    public deleteItem = () => {
        instance.delete(this.getEntityPath(), {
            data: this.state.item
        })
            .then(res => {
                if (res.status === 200) {

                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false, errorMsg: err.message });
            });
    }

    render() {
        if (this.state.redirectUrl) {
            <Navigate to={this.state.redirectUrl} replace={true} />
        }
        return (
            <React.Fragment></React.Fragment>
        );
    }
}