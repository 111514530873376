import React from 'react';
import { Link, Navigate, NavLink, Outlet } from 'react-router-dom';
import 'semantic-ui-css/components/accordion.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/divider.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/item.min.css';
import 'semantic-ui-css/components/list.min.css';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/modal.min.css';
import 'semantic-ui-css/components/placeholder.min.css';
import 'semantic-ui-css/components/search.min.css';
import 'semantic-ui-css/components/sidebar.min.css';
import 'semantic-ui-css/components/transition.min.css';
import { Button, Menu, Sidebar } from 'semantic-ui-react';
import './admin.layout.scss';

import { IoIosMenu } from 'react-icons/io';
import 'semantic-ui-css/components/embed.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/nag.min.css';
import './admin.layout.scss';

interface LayoutProps {
}
interface LayoutState {
    sidebarOpen: boolean
    activeMenuItem: string
    redirect: string
}

class AdminLayout extends React.Component<LayoutProps, LayoutState> {

    activeMenuItem = "";

    basicMenuItems = [
        { name: "List", addUrl: "" },
        { name: "New", addUrl: "new" }
    ];

    public menuList = [
        { name: 'Exercise', url: '/exercises', children: this.basicMenuItems },
        { name: 'Lesson', url: '/lessons', children: this.basicMenuItems },
        { name: 'Unit', url: '/units', children: this.basicMenuItems },
        { name: 'Section', url: '/sections', children: this.basicMenuItems },
        { name: 'Users', url: '/users' },
        { name: 'User lessons', url: '/user-lessons' },
        // { name: 'Organization', url: '/organizations' },
        // { name: 'Sponsor', url: '/sponsors', children: this.basicMenuItems },
        // { name: 'Settings', url: '/settings' },
    ]

    constructor(props: any) {
        super(props);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.state = {
            sidebarOpen: true,
            activeMenuItem: this.menuList[0].name,
            redirect: "",
        };
    }

    componentDidMount() {
        this.setState({ sidebarOpen: false, activeMenuItem: this.menuList[0].name });
    }

    toggleSidebar = () => {
        this.setState({ sidebarOpen: !this.state.sidebarOpen })
    }

    hideSidebar = () => {
        this.setState({ sidebarOpen: false })
    }

    handleMenuItemClick(name: string) {
        this.setState({ activeMenuItem: name });
    }

    logout() {
        localStorage.removeItem('biSurlaaToken')
        this.setState({ redirect: "/login" })
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} replace/>
        }
        return (
            <div className="admin-layout">
                <div className="upper-container">
                    <div className="upper-section">

                        <div className="left section">
                            <div className="burger-menu" onClick={() => this.toggleSidebar()}>
                                <IoIosMenu className="icon icon-menu"/>
                            </div>
                            <div className="left-content">
                            </div>
                        </div>

                        <div className="right section">
                            <Menu size='tiny'>
                                <Link to="/">
                                    <Menu.Item name='Home' />
                                </Link>
                                {/* <Menu.Item
                                    name='Мессеж' */}
                                {/* // onClick={this.handleItemClick} */}
                                {/* /> */}

                                <Menu.Menu position='right' size='tiny'>
                                    {/* <Dropdown item text='Меню'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>Email</Dropdown.Item>
                                            <Dropdown.Item>SMS</Dropdown.Item>
                                            <Dropdown.Item></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}

                                    <Menu.Item>
                                        <Button primary onClick={() => this.logout()}>Log out</Button>
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                        </div>

                    </div>
                </div>

                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='push'
                        icon='labeled'
                        vertical
                        visible={this.state.sidebarOpen}
                    >
                        {/* <Menu.Item>
                            <Menu.Header>Menu</Menu.Header>
                            <Menu.Menu>
                                <Link to="/report/orders">
                                    <Menu.Item name="menu 1"></Menu.Item>
                                </Link>
                            </Menu.Menu>
                        </Menu.Item> */}
                        {this.menuList.map((menuItem) => {
                            return menuItem.children ?
                                <Menu.Item key={"menu-sub-item-" + menuItem.name}>
                                    <Menu.Header>{menuItem.name}</Menu.Header>
                                    <Menu.Menu>
                                        {
                                            menuItem.children.map((subItem: any) => {
                                                return <Menu.Item key={"menu-sub-item-" + subItem.name}
                                                    as={NavLink}
                                                    link={true}
                                                    to={menuItem.url + "/" + subItem.addUrl}
                                                    name={subItem.name}
                                                    active={this.state?.activeMenuItem === menuItem.name + "-" + subItem.name}
                                                    onClick={() => this.handleMenuItemClick(menuItem.name + "-" + subItem.name)}
                                                />
                                            })
                                        }
                                    </Menu.Menu>
                                </Menu.Item>
                                :
                                <Menu.Item key={"menu-sub-item-" + menuItem.name}
                                    as={NavLink}
                                    link={true}
                                    to={menuItem.url}
                                    name={menuItem.name}
                                    active={this.state?.activeMenuItem === menuItem.name + "-" + menuItem.name}
                                    onClick={() => this.handleMenuItemClick(menuItem.name + "-" + menuItem.name)}
                                />
                        })
                        }
                    </Sidebar>

                    <Sidebar.Pusher onClick={() => this.hideSidebar()} className="admin-layout-content">
                        <Outlet />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>
        );
    }
}

export default AdminLayout;