import React from 'react';
import { FaRegUser } from "react-icons/fa";
import { IoWarningOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, Navigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { instance } from '../../axios/AxiosInstance';

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    max-width: 600px;
    margin-top: 40px;
    width: 100%;
    max-width: 350px;
`;

const FormGroup = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
`;

const ForgotLine = styled.div`
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
`;

const Div = styled.div`
    position: relative;
`;

const Icon = styled.div`
    cursor: default;
    height: 100%;
    margin: 0;
    position: absolute;
    opacity: 0.5;
    left: 0;
    top: 10px;
    transition: opacity .3s ease;
    width: 40px;
`;

interface LoginProps {

}

interface LoginState {
    redirect: string,
    errorMsg: string,
    username: string,
    password: string,
    loading: boolean
}

class AdminLoginComponent extends React.Component<LoginProps, LoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            redirect: "",
            errorMsg: "",
            username: "",
            password: "",
            loading: false
        }
        this.submit = this.submit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    onUsernameChange(e?: any) {
        this.setState({ username: e.target.value });
    }

    onPasswordChange(e?: any) {
        this.setState({ password: e.target.value });
    }

    submit = () => {
        if (!this.state.username) {
            this.setState({ errorMsg: "Та хэрэглэгчийн нэр оруулна уу" })
            this.scrollToForm()
            return;
        }

        if (!this.state.password) {
            this.setState({ errorMsg: "Та нууц үг оруулна уу" })
            this.scrollToForm()
            return;
        }

        this.setState({ errorMsg: "", loading: true })

        instance.post('admin/signin', {
            username: this.state.username,
            password: this.state.password
        })
        .then((res: any) => {
            console.log("Successful login", res)
            localStorage.setItem('biSurlaaToken', String(res.data.token))
            this.setState({ redirect: "/", loading: false })
        })
        .catch((err: any) => {
            console.error(err)
            if (err.response.status === 401) {
                this.setState({ errorMsg: err.response.data.message, loading: false })
            } else {
                this.setState({ errorMsg: "Something happenned!", loading: false })
            }
        })
    }

    scrollToForm = () => {
        let element = document.getElementById("login-form");
        if (element) {
            element.scrollIntoView();
        }
    }

    handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            this.submit();
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} replace />;
        }
        return (
            <div className="page login-page margin-top-50">
                <Section>
                    <div className="logo-text">
                        <div className="line1">Bi Surlaa</div>
                        <div className="line2">by GobiCraft</div>
                    </div>
                </Section>

                <div className="flex-div">
                    <MyForm id="login-form">
                        { this.state.errorMsg ? 
                            <FormGroup>
                                <IoWarningOutline name="warning sign" color='orange' size={19}/>
                                <span>{"  " + this.state.errorMsg}</span>
                            </FormGroup> : null
                        }
                        <FormGroup>
                            <Div className="width-100 ui left icon input">
                                <Icon>
                                    <FaRegUser className="icon" width={8} height={8}/>
                                </Icon>
                                <input type="email" className="width-100" name="email" value={this.state.username} onChange={this.onUsernameChange} placeholder="" />
                            </Div>
                        </FormGroup>
                        <FormGroup>
                            <Div className="width-100 ui left icon input">
                                <Icon>
                                    <RiLockPasswordLine className="icon" width={8} height={8}/>
                                </Icon>
                                <input className="width-100" type="password" name="password" value={this.state.password} onChange={this.onPasswordChange} onKeyDown={this.handleKeyPress}/>
                            </Div>
                        </FormGroup>
                        <FormGroup>
                            <Button className="ui fluid large red submit button" disabled={this.state.loading} onClick={this.submit}>Login</Button>
                        </FormGroup>
                        <FormGroup>
                            <Link to="/forgot-password">
                                <ForgotLine>Forgot password</ForgotLine>
                            </Link>
                        </FormGroup>
                    </MyForm>
                </div>
            </div>
        );
    }
}

export default AdminLoginComponent;