import React from 'react';
import { Navigate } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import GlobalData from '../../../services/GlobalData';
import MyListProps from '../interfaces/MyListProps';
import MyListState from '../interfaces/MyListState';
import MyList from '../MyList';

interface StudyListState extends MyListState {
    lang: string
    section_id: number;
    unit_id: number;
    lesson_id: number;
    sections: any[];
    units: any[];
    lessons: any[];
}

export default class StudyList extends MyList<MyListProps, StudyListState> {
    entity = ""
    queryParam = ""

    componentDidMount(): void {
        if (this.entity !== "section") {
            this.getSectionDropdownData("mn");
        }
    }

    //TODO save filter selected values in the url query params
    onStateValueChange = (e: React.SyntheticEvent, { name, value }: any) => {
        this.setState({
            ...this.state,
            [name]: value,
        }, () => {
            this.handleStateChange(name, value);
        });
    };

    handleStateChange = (name: string, value: any) => {
        if (name === "lang") {
            if (this.entity === "section") {
                this.queryParam = `?lang=${value}`;
                this.getData();
            } else {
                this.getSectionDropdownData(value);
            }
        }
        else if (name === "section_id") {
            if (this.entity === "unit") {
                this.queryParam = `?section_id=${value}`;
                this.getData();
            } else {
                this.getUnitsDropdownData(value);
            }
        }
        else if (name === "unit_id") {
            if (this.entity === "lesson") {
                this.queryParam = `?unit_id=${value}`;
                this.getData();
            } else {
                this.getLessonsDropdownData(value);
            }
        }
        else if (name === "lesson_id") {
            if (this.entity === "exercise") {
                this.queryParam = `?lesson_id=${value}`;
                this.getData();
            }
        }

        // only put actual id, not the default 0 value to the query params
        if (value) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set(name, value);
            window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`);
        }
    }

    getQueryParam = (param: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        let value = searchParams.get(param);
        if (value) {
            return Number(value);
        } else {
            return 0;
        }
    }

    getSectionDropdownData = (lang: string) => {
        GlobalData.getData("section", "lang=" + lang, true)
            .then(res => {
                if (res.data.data) {
                    const options = res.data.data.map((a: any) => ({
                        key: a.id,
                        value: a.id,
                        text: a.name,
                    }));
                    // this.setState({ sections: options });
                    let section_id_query_param = this.getQueryParam("section_id");
                    this.setState({ data: [], sections: options, section_id: section_id_query_param, unit_id: 0, units: [], lesson_id: 0, lessons: [] });
                    // need to trigger state change handler because on the above line, the state is changed programatically
                    this.handleStateChange("section_id", section_id_query_param);
                }
            })
            .catch(err => {
                console.error(err);
                return undefined;
            });
    }

    getUnitsDropdownData = (section_id: number) => {
        GlobalData.getData("unit", `section_id=${section_id}`, true)
            .then(res => {
                if (res.data.data) {
                    const options = res.data.data.map((a: any) => ({
                        key: a.id,
                        value: a.id,
                        text: a.name,
                    }));
                    // this.setState({ units: options });
                    let unit_id_query_param = this.getQueryParam("unit_id");
                    this.setState({ data: [], units: options, unit_id: unit_id_query_param, lesson_id: 0, lessons: [] });
                    // need to trigger state change handler because on the above line, the state is changed programatically
                    this.handleStateChange("unit_id", unit_id_query_param);
                }
            })
            .catch(err => {
                console.error(err);
                return undefined;
            });
    }

    getLessonsDropdownData = (unit_id: string) => {
        GlobalData.getData("lesson", "unit_id=" + unit_id, true)
            .then(res => {
                if (res.data.data) {
                    const options = res.data.data.map((a: any) => ({
                        key: a.id,
                        value: a.id,
                        text: a.name,
                    }));
                    // this.setState({ lessons: options });
                    let lesson_id_query_param = this.getQueryParam("lesson_id");
                    this.setState({ data: [], lesson_id: lesson_id_query_param, lessons: options });
                    // need to trigger state change handler because on the above line, the state is changed programatically
                    this.handleStateChange("lesson_id", lesson_id_query_param);
                }
            })
            .catch(err => {
                console.error(err);
                return undefined;
            });
    }
  
    render() {
        if (this.state.redirectUrl) {
            <Navigate to={this.state.redirectUrl} replace={true} />
        }
        return (
            <React.Fragment></React.Fragment>
        );
    }
}