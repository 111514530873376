import { Link } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import MyList from './MyList';
import MyListProps from './interfaces/MyListProps';
import MyListState from './interfaces/MyListState';

export default class UserLessonList extends MyList<MyListProps, MyListState> {
    entity = "user-lesson";
    queryParam = "?v=all";// needed due to getData()

    constructor(props: any) {
        super(props);
        this.state = { loading: false, data: [], errorMsg: "", successMsg: "", redirectUrl: "", page: { page: 1, size: 15, totalPages: 1, totalRows: 0 } };
    }

    render() {
        return (
            <section className="full-width">
            <h2>User lessons</h2>

            <Dimmer active={this.state.loading} inverted>
                <Loader size='medium'></Loader>
            </Dimmer>

            <Table striped width={16}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No</Table.HeaderCell>
                        <Table.HeaderCell>User ID</Table.HeaderCell>
                        <Table.HeaderCell>Lang</Table.HeaderCell>
                        <Table.HeaderCell>Last section index</Table.HeaderCell>
                        <Table.HeaderCell>Last unit index</Table.HeaderCell>
                        <Table.HeaderCell>Last lesson index</Table.HeaderCell>
                        <Table.HeaderCell>Last exercise index</Table.HeaderCell>
                        <Table.HeaderCell>Started at</Table.HeaderCell>
                        <Table.HeaderCell>Updated at</Table.HeaderCell>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.state?.data?.map((row: any, index: number) => {
                        return <Table.Row key={row.id}>
                            <Table.Cell>
                                <strong>
                                    <Link className="underline hover-blue" to={"/user-lesson/" + row.id}>{index + 1}</Link>
                                </strong>
                            </Table.Cell>
                            <Table.Cell>{String(row.user_id)}</Table.Cell>
                            <Table.Cell>{String(row.lang)}</Table.Cell>
                            <Table.Cell>{String(row.last_section_index)}</Table.Cell>
                            <Table.Cell>{String(row.last_unit_index)}</Table.Cell>
                            <Table.Cell>{String(row.last_lesson_index)}</Table.Cell>
                            <Table.Cell>{String(row.last_exercise_index)}</Table.Cell>
                            <Table.Cell>{String(row.started_at)}</Table.Cell>
                            <Table.Cell>{String(row.updated_at)}</Table.Cell>
                            <Table.Cell>{String(row.version)}</Table.Cell>
                        </Table.Row>
                    })
                    }
                </Table.Body>
            </Table>
        </section>
        );
    };
}