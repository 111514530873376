import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/components/table.min.css';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import MyList from './MyList';
import MyListProps from './interfaces/MyListProps';
import MyListState from './interfaces/MyListState';

export default class UserList extends MyList<MyListProps, MyListState> {
    entity = "user";
    queryParam = "?v=all"// needed due to getData()

    constructor(props: any) {
        super(props);
        this.state = { loading: false, data: [], errorMsg: "", successMsg: "", redirectUrl: "", page: { page: 1, size: 15, totalPages: 1, totalRows: 0 } };
    }

    render() {
        return (
            <section className="full-width">
            <h2>User list</h2>

            <Dimmer active={this.state.loading} inverted>
                <Loader size='medium'></Loader>
            </Dimmer>

            <Table striped width={16}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>First name</Table.HeaderCell>
                        <Table.HeaderCell>Last name</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Ext</Table.HeaderCell>
                        <Table.HeaderCell>Mobile number</Table.HeaderCell>
                        <Table.HeaderCell>Timezone</Table.HeaderCell>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.state?.data?.map((row: any, index: number) => {
                        return <Table.Row key={row.id}>
                            <Table.Cell>{String(index + 1)}</Table.Cell>
                            <Table.Cell>
                                <strong>
                                    <Link className="underline hover-blue" to={"/users/" + row.id}>{row.id}</Link>
                                </strong>
                            </Table.Cell>
                            <Table.Cell>{String(row.first_name)}</Table.Cell>
                            <Table.Cell>{String(row.last_name)}</Table.Cell>
                            <Table.Cell>{String(row.username)}</Table.Cell>
                            <Table.Cell>{String(row.mobile_ext)}</Table.Cell>
                            <Table.Cell>{String(row.mobile_number)}</Table.Cell>
                            <Table.Cell>{String(row.timezone)}</Table.Cell>
                            <Table.Cell> {row.active ? <IoIosCheckmarkCircleOutline /> : null} </Table.Cell>
                            <Table.Cell>{String(row.version)}</Table.Cell>

                        </Table.Row>
                    })
                    }
                </Table.Body>
            </Table>
        </section>
        );
    };
}