import React from 'react';
import './dashboard.styles.scss';

class Dashboard extends React.Component {
    render() {
        return (
            <div className="page admin-page">
                <h3>Please open the sidebar on the left side</h3>
            </div>
        );
    }
}

export default Dashboard;