import IStudyModel from "./IStudyModel";

export default class UnitModel implements IStudyModel {
    id: number;
    section_id: number;
    index: number;
    name: string;
    description: string;
    version: string = "v0";
    active: boolean;

    public constructor(id: number, section_id: number, index: number, name: string, description: string, active: boolean, version: string) {
        this.id = id;
        this.section_id = section_id;
        this.index = index;
        this.name = name;
        this.description = description;
        this.active = active;
        this.version = version;
    }
}