import IOption from "../answer/IOption";
import QuestionChunk from "./QuestionChunkText";

export default class Question {
  index: number = 0;
  question_mode: string = "";
  answer_mode: string = "";
  character: string = "";
  graphic: string = "";
  question_chunks: QuestionChunk[] = [];
  options: IOption[] = [];
  correct_answers: string[] = [];
}