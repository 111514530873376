import { Button, Container, Dimmer, Dropdown, Form, Loader } from 'semantic-ui-react';
import Utils from '../../../helpers/Utils';
import UnitModel from '../../../models/study/UnitModel';
import GlobalData from '../../../services/GlobalData';
import StudyFormState from '../interfaces/StudyFormState';
import StudyForm from './StudyForm';

export default class UnitForm extends StudyForm<UnitModel, StudyFormState<UnitModel>> {
    entity = 'unit'
    queryParam = "?section_id=1732681449576"
    entityLevel = 2;

    constructor(props: any) {
        super(props);
        this.state = { lang: "mn", section_id: 0, unit_id: 0, lesson_id: 0, sections: [], units: [], lessons: [], loading: false, editing: false, item: {} as UnitModel, successMsg: "", errorMsg: "", redirectUrl: "" };
    }

    componentDidMount(): void {
        this.getSectionsDropdownData("mn");

        const paths = window.location.pathname.split('/');
        if (paths[2] === "new") {
            this.setState({ item: new UnitModel(0, 0, 0, "", "", true, "v0") });
        } else {
            this.setState({ editing: true });
            this.getItemQueryParams = `section_id=${paths[2]}&index=${paths[3]}`;
            this.getItem();
        }
    }

    validate = (): boolean => {
        this.setState({ errorMsg: "" });
        if (Utils.isEmpty(this.state.item.index)) {
            this.setState({ errorMsg: "Enter index please. This will be the placement of this unit in the section" });
            return false;
        }
        if (Utils.isEmpty(this.state.item.name)) {
            this.setState({ errorMsg: "Enter name of the unit please." });
            return false;
        }
        if (Utils.isEmpty(this.state.item.description)) {
            this.setState({ errorMsg: "Enter description of the unit please." });
            return false;
        }
        return true;
    }

    render() {
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                
                <h2>{!this.state.editing ? 'New' : ''} Unit</h2>
                
                <Form onSubmit={() => this.save()}>
                    <Form.Group>
                        {this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null}
                        {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
                    </Form.Group>
                    <Form.Field>
                        <label>Language</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select language"
                            fluid
                            selection
                            required
                            labeled
                            name="lang"
                            disabled={this.state.editing}
                            options={GlobalData.languageOptions}
                            value={this.state.lang}
                            onChange={this.handleStateDropdownChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Section</label>
                    </Form.Field>
                    <Form.Field>
                        <Dropdown
                            placeholder="Select section"
                            fluid
                            search
                            selection
                            required
                            disabled={this.state.editing}
                            name="section_id"
                            options={this.state.sections}
                            value={this.state.item.section_id}
                            onChange={this.handleItemDropdownChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Index</label>
                        <input
                            type="number"
                            name="index"
                            required
                            disabled={this.state.editing}
                            value={this.state.item.index}
                            onChange={(e) => this.handleChange(e, { name: 'index', value: Number(e.target.value) })}
                            min={0}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            required
                            value={this.state.item.name}
                            onChange={(e) => this.handleChange(e, { name: 'name', value: e.target.value })}
                            placeholder="Enter unit name"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            required
                            value={this.state.item.description}
                            onChange={(e) => this.handleChange(e, { name: 'description', value: e.target.value })}
                            placeholder="Enter unit description"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Active</label>
                        <input
                            type="checkbox"
                            name="active"
                            checked={this.state.item.active}
                            onChange={(e) => this.handleChange(e, { name: 'active', value: e.target.checked })}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Version</label>
                        <Dropdown
                            placeholder="Select a version"
                            fluid
                            selection
                            search
                            required
                            disabled
                            name="version"
                            options={GlobalData.versionOptions}
                            value={this.state.item.version}
                            onChange={this.handleItemDropdownChange}
                        />
                    </Form.Field>

                    <Button type="submit" primary>{this.state.editing ? "Update" : "Create"} unit</Button>
                </Form>
            </Container>
        );
    }
}
