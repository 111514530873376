import { useState } from "react";
import { Button, Dropdown, Form } from "semantic-ui-react";
import Utils from "../../../../helpers/Utils";
import GraphicOption from "../../../../models/problem/answer/GraphicOption";
import Option from "../../../../models/problem/answer/Option";
import Question from "../../../../models/problem/question/Question";
import QuestionChunkAudio from "../../../../models/problem/question/QuestionChunkAudio";
import QuestionChunkText from "../../../../models/problem/question/QuestionChunkText";
import GlobalData from "../../../../services/GlobalData";
import "./QuestionForm.scss";
import QuestionPreview from "./QuestionPreview";

function QuestionForm({ newQuestion, onChange }: { newQuestion: Question, onChange: (val: Question) => void }) {

  const [answerModeOptions, setAnswerModeOptions] = useState<any[]>([])
  const [questionText, setQuestionText] = useState("");
  const [questionChunkType, setQuestionChunkType] = useState("");
  const [selectedChunkIndex, setSelectedChunkIndex] = useState(-1);

  const [optionType, setOptionType] = useState("")
  const [optionTypes, setOptionTypes] = useState<any[]>([])
  const [optionText, setOptionText] = useState("");
  const [optionTextMatch, setOptionTextMatch] = useState("");
  const [optionGraphicPath, setOptionGraphicPath] = useState("");
  const [correctAnswerText, setCorrectAnswerText] = useState("");
  const [selectWords, setSelectWords] = useState<string[]>([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const setNewQuestion = (value: Question) => {
    onChange(value);
  }

  const addQuestionText = () => {
    if (Utils.isEmpty(questionText) || Utils.isEmpty(questionChunkType)) {
      return;
    }
    let chunks = questionText.split(" ");
    let questionChunks: (QuestionChunkText | QuestionChunkAudio)[] = [];
    for (let chunk of chunks) {
      let questionChunk = questionChunkType === "TEXT" ? new QuestionChunkText(chunk) : new QuestionChunkAudio(chunk);
      questionChunks.push(questionChunk);
    }
    setNewQuestion({
      ...newQuestion,
      question_chunks: [...(newQuestion.question_chunks || []), ...questionChunks]
    });
    setQuestionText("");
  }

  const deleteQuestionText = (index: number) => {
    let tmpChunks = [...newQuestion.question_chunks || []];
    tmpChunks.splice(index, 1);
    setNewQuestion({
      ...newQuestion,
      question_chunks: tmpChunks
    })
  }

  const deleteOption = (index: number) => {
    setNewQuestion({
      ...newQuestion,
      correct_answers: []
    })
    setTimeout(() => {
      let tmpList = [...newQuestion.options || []];
      tmpList.splice(index, 1);
      setNewQuestion({
        ...newQuestion,
        options: tmpList
      })
    });
  }

  const deleteSelectWordOption = (index: number) => {
    // setNewQuestion({
    //   ...newQuestion,
    //   correct_answers: []
    // })

    let word = selectWords[index];
    let tmpOptions = [...newQuestion.options || []];
    let tmpSelectWords = [...selectWords];

    tmpSelectWords.splice(index, 1);

    for (let oi=0; oi<tmpOptions.length; oi++) {
      if (tmpOptions[oi].text === word) {
        tmpOptions.splice(oi, 1);
        break;
      }
    }

    let correctAnswersUpdated = [];

    for (let word of selectWords) {
      let index = tmpOptions.map(e => e.text).indexOf(word);
      if (index !== -1) {
        correctAnswersUpdated.push(String(index));
      }
    }

    setSelectWords(tmpSelectWords);
    setNewQuestion({
      ...newQuestion,
      options: tmpOptions,
      correct_answers: correctAnswersUpdated
    })
  }

  const deleteCorrectAnswer = (index: number) => {
    let tmpList = [...newQuestion.correct_answers || []];
    tmpList.splice(index, 1);
    setNewQuestion({
      ...newQuestion,
      correct_answers: tmpList
    })
  }

  const addOption = () => {//TODO add more text after
    if (!optionText || !optionType) {
      return;
    }

    if (newQuestion.answer_mode === "SELECT_WORDS") {
      return addSelectWords();
    }
    else {
      let optionsNew = [...newQuestion.options || []];

      if (optionType === "TEXT_AUDIO") {
        optionsNew.push(new Option(
          "TEXT_AUDIO",
          optionText
        ));
      } else if (optionType === "GRAPHIC") {
        optionsNew.push(new GraphicOption(
          optionText,
          optionGraphicPath
        ));
      } else if (optionType === "AUDIO") {
        optionsNew.push(new Option(
          "AUDIO",
          optionText,
        ));
      } else {
        optionsNew.push(new Option(
          "TEXT",
          optionText,
        ));
      }

      if (newQuestion.answer_mode === "MATCH") {
        optionsNew.push(new Option(
          "AUDIO",
          optionTextMatch
        ));
      }

      setNewQuestion({
        ...newQuestion,
        options: optionsNew
      });

      setOptionText("");
      setOptionTextMatch("");
      setOptionGraphicPath("");
    }
  }

  const addSelectWords = () => {
    let optionsUpdated = [...newQuestion.options || []];
    let newWords = optionText.split(" ");

    for (let i = 0; i < newWords.length; i++) {
      let word = newWords[i];
      if (optionType === "TEXT_AUDIO") {
        optionsUpdated.push(new Option(
          "TEXT_AUDIO",
          word
        ));
      } else if (optionType === "GRAPHIC") {
        optionsUpdated.push(new GraphicOption(
          word,
          optionGraphicPath
        ));
      } else if (optionType === "AUDIO") {
        optionsUpdated.push(new Option(
          "AUDIO",
          word,
        ));
      } else {
        optionsUpdated.push(new Option(
          "TEXT",
          word,
        ));
      }
    }
    
    optionsUpdated = Utils.shuffle(optionsUpdated);
    let wordsUpdated = selectWords.concat(newWords);
    let correctAnswersUpdated: string[] = [];

    for (let word of wordsUpdated) {
      let index = optionsUpdated.map(e => e.text).indexOf(word);
      if (index !== -1) {
        correctAnswersUpdated.push(String(index));
      }
    }

    setSelectWords(wordsUpdated);

    setNewQuestion({
      ...newQuestion,
      options: optionsUpdated,
      correct_answers: correctAnswersUpdated
    });

    setOptionText("");
    setOptionTextMatch("");
    setOptionGraphicPath("");
  }

  const reshuffleOptions = () => {
    let optionsNew = Utils.shuffle(newQuestion.options);
    let correctAnswersUpdated = [];

    for (let word of selectWords) {
      let index = optionsNew.map(e => e.text).indexOf(word);
      if (index !== -1) {
        correctAnswersUpdated.push(String(index));
      }
    }

    setNewQuestion({
      ...newQuestion,
      options: optionsNew,
      correct_answers: correctAnswersUpdated
    });
  }

  const getOptionTypes = (value: string) => {
    const tmpList = GlobalData.OptionTypesMap[value as keyof typeof GlobalData.OptionTypesMap].map((a: string) => ({
      key: a,
      value: a,
      text: a,
    }))
    setOptionTypes(tmpList)
  }

  const handleNewQuestionDropdownChange = (e: React.SyntheticEvent, { name, value }: any) => {
    if (name === "question_mode") {
      const tmpOptions = GlobalData.AnswerModesMap[value as keyof typeof GlobalData.AnswerModesMap].map((a: string) => ({
        key: a,
        value: a,
        text: a,
      }))
      setAnswerModeOptions(tmpOptions)

      // onChange(new Question(0, value, ""))

      if (value === "MATCH") {
        getOptionTypes("MATCH");
        setOptionType("TEXT_AUDIO")

        onChange({
          ...newQuestion,
          question_mode: value,
          answer_mode: "MATCH"
        })
      }
      else if (value === "TRANSLATE_SENTENCE") {
        getOptionTypes("SELECT_WORDS");

        onChange({
          ...newQuestion,
          question_mode: value,
          answer_mode: "SELECT_WORDS"
        })
      }
      else {
        onChange({
          ...newQuestion,
          question_mode: value,
        })
      }
    }
    else if (name === "answer_mode") {
      getOptionTypes(value);
      // setOptionType("TEXT_AUDIO")

      if (value === "MATCH" || value === "SELECT_WORDS") {
        setSelectWords([]);
        onChange({
          ...newQuestion,
          answer_mode: value,
          options: [],
          correct_answers: []
        })
      }
      else {
        onChange({
          ...newQuestion,
          answer_mode: value,
        })
      }
    }
    else if (name === "character") {
      let graphicPath = "";
      if (value === "Bear") {
        graphicPath = "assets/gif/bear/"
      }
      onChange({
        ...newQuestion,
        character: value,
        graphic: graphicPath
      })
    }
    else {
      onChange({
        ...newQuestion,
        [name]: value
      })
    }
  }

  const handleNewQuestionChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: any) => {
    onChange({
      ...newQuestion,
      [name]: value,
    })
  };

  const toggleQuestionChunk = (index: number) => {
    if (selectedChunkIndex === -1) {
      setSelectedChunkIndex(index);
    } else if (selectedChunkIndex === index) {
      setSelectedChunkIndex(-1);
    } else {
      let tmpList = [...newQuestion.question_chunks];
      let lowerIndex = index < selectedChunkIndex ? index : selectedChunkIndex;
      let higherIndex = index < selectedChunkIndex ? selectedChunkIndex : index;
      tmpList[lowerIndex].text = tmpList[lowerIndex].text + " " + tmpList[higherIndex].text;
      tmpList.splice(higherIndex, 1);
      setSelectedChunkIndex(-1);
      setNewQuestion({
        ...newQuestion,
        question_chunks: tmpList
      })
    }
  }

  const toggleOption = (index: number) => {
    if (selectedOptionIndex === -1) {
      setSelectedOptionIndex(index);
    } else if (selectedOptionIndex === index) {
      setSelectedOptionIndex(-1);
    } else {
      let tmpList = [...newQuestion.options];
      let lowerIndex = index < selectedOptionIndex ? index : selectedOptionIndex;
      let higherIndex = index < selectedOptionIndex ? selectedOptionIndex : index;
      tmpList[lowerIndex].text = tmpList[lowerIndex].text + " " + tmpList[higherIndex].text;
      tmpList.splice(higherIndex, 1);
      setSelectedOptionIndex(-1);
      setNewQuestion({
        ...newQuestion,
        options: tmpList
      })
    }
  }

  const changeOptionText = (value: string) => {
    setOptionText(value)
    if (Utils.isNotEmpty(value) && Utils.isEmpty(optionType)) {
      if (Utils.containsMongolianCryllic(value)) {
        setOptionType("TEXT_AUDIO")
      }
      else {
        setOptionType("TEXT")
      }
    }
  }

  const changeQuestionText = (value: string) => {
    setQuestionText(value)

    if (Utils.isNotEmpty(value) && Utils.isEmpty(questionChunkType)) {
      if (Utils.containsMongolianCryllic(value)) {
        setQuestionChunkType("TEXT_AUDIO")
      }
      else {
        setQuestionChunkType("TEXT")
      }
    }
  }

  const addCorrectTextAnswer = () => {
    if (!correctAnswerText) {
      return;
    }
    try {
      setNewQuestion({
        ...newQuestion,
        correct_answers: [correctAnswerText]
      });
      setCorrectAnswerText("");
    }
    catch (e) {
      alert(e)
    }
  }

  const checkAsCorrect = (index: number) => {
    setNewQuestion({
      ...newQuestion,
      correct_answers: [String(index)]
    });
  }

  return (
    <section id="question-form">
      <Form>
        <h2>New question:</h2>

        <Form.Field>
          <label>Question type</label>
        </Form.Field>
        <Form.Field>
          <Dropdown
            placeholder="Select a question type"
            selection
            required
            name="question_mode"
            options={GlobalData.QuestionModes}
            value={newQuestion.question_mode}
            onChange={handleNewQuestionDropdownChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Answer type</label>
        </Form.Field>
        <Form.Field>
          <Dropdown
            placeholder="Select an answer type"
            selection
            required
            name="answer_mode"
            options={answerModeOptions}
            value={newQuestion.answer_mode}
            onChange={handleNewQuestionDropdownChange}
          />
        </Form.Field>

        {/* <Form.Field>
          <label>Index</label>
          <input
            type="number"
            name="index"
            required
            disabled
            value={newQuestion.index}
            onChange={(e) => handleNewQuestionChange(e, { name: 'index', value: Number(e.target.value) })}
            min={0}
          />
        </Form.Field> */}

        <Form.Field>
          <label>Character</label>
        </Form.Field>

        <Form.Field>
          <Dropdown
            placeholder="Select a character"
            selection
            required
            name="character"
            options={GlobalData.CharacterOptions}
            value={newQuestion.character}
            onChange={handleNewQuestionDropdownChange}
          />
        </Form.Field>

        <Form.Field>
          <label>Graphic path</label>
          <input
            type="text"
            name="character"
            required
            value={newQuestion.graphic}
            onChange={(e) => handleNewQuestionChange(e, { name: 'graphic', value: e.target.value })}
          />
        </Form.Field>
      </Form>

      {!newQuestion?.question_mode ? null :
        <Form onSubmit={(e) => addQuestionText()}>
          <h2>Questions text:</h2>

          {Utils.isEmpty(newQuestion.question_mode) ?
            <Form.Field>
              Please select question type before adding answer questions!
            </Form.Field> : null}

          <Form.Field>
            <label>Enter question text</label>
          </Form.Field>

          <Form.Field className={window.innerWidth > 600 ? 'flex-div' : ''}>
            <input
              type="text"
              name="questionText"
              required
              value={questionText}
              onChange={(e) => changeQuestionText(e.target.value)}
              placeholder="Enter question as text"
            />

            <Dropdown
              placeholder="Select a question text type"
              selection
              name="questionChunkType"
              options={GlobalData.QuestionChunkTypes}
              value={questionChunkType}
              disabled={newQuestion?.question_chunks?.length > 0}
              onChange={(e, data: any) => setQuestionChunkType(data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Button disabled={!questionText || !questionChunkType}>Add text</Button>
          </Form.Field>

          {newQuestion?.question_chunks?.length > 0 ?
            <>
              <div className="toggle-text-container">
                <div className="toggle-text-box">
                  {newQuestion.question_chunks.map((question, index: number) => {
                    return <div className={"toggle-text" + (selectedChunkIndex === index ? " selected" : "")}
                      key={"text-" + index}
                      onClick={() => toggleQuestionChunk(index)}>{question.text}</div>
                  })}
                </div>
                <div className="toggle-text-box">
                  {newQuestion.question_chunks.map((question, index: number) => {
                    return <div key={"type-" + index}>{question.type}</div>
                  })}
                </div>
                <div className="toggle-text-box">
                  {newQuestion.question_chunks.map((question, index: number) => {
                    return <div key={"delete-" + index}>
                      <span className="delete-item" onClick={() => deleteQuestionText(index)}>delete</span>
                    </div>
                  })}
                </div>
              </div>
              <br />
            </> : null}
          <br />
        </Form>}
      <hr />
      {/* ANSWER OPTIONS */}
      {!newQuestion?.answer_mode || newQuestion.answer_mode === "TYPE" ? null :
        <Form onSubmit={addOption}>
          <h2>Answer options:</h2>

          {Utils.isEmpty(newQuestion.answer_mode) ?
            <Form.Field>
              Please select answer type before adding answer options!
            </Form.Field> : null}

          <Form.Field>
            {newQuestion?.question_mode === "MATCH" ?
              <label>Enter matching pairs 2 by 2</label>
              :
              <label>Enter answer options</label>
            }
          </Form.Field>

          <Form.Field className='flex-div'>
            <input
              type="text"
              name="optionText"
              value={optionText}
              onChange={(e) => changeOptionText(e.target.value)}
              placeholder="Enter option as text or number"
            />

            {newQuestion?.question_mode !== "MATCH" ? null :
              <input
                type="text"
                name="optionTextMatch"
                value={optionTextMatch}
                onChange={(e) => setOptionTextMatch(e.target.value)}
                placeholder="Enter matching option"
              />}

            <Dropdown
              placeholder="Select option type"
              selection
              required
              name="optionType"
              disabled={newQuestion.options?.length > 0}
              options={optionTypes}
              value={optionType}
              onChange={(e, data: any) => setOptionType(data.value)}
            />
          </Form.Field>

          {optionType !== "GRAPHIC" ? null :
            <Form.Field>
              <input
                type="text"
                name="optionGraphicPath"
                value={optionGraphicPath}
                onChange={(e) => setOptionGraphicPath(e.target.value)}
                placeholder="Enter image url"
              />
            </Form.Field>}

          <Form.Field>
            <Button disabled={(optionType !== 'GRAPHIC' && !optionText) || !optionType || (newQuestion.answer_mode === "MATCH" && !optionTextMatch) || (optionType === "GRAPHIC" && !optionGraphicPath)}>Add answer option</Button>
          </Form.Field>

          <br />

          {newQuestion.answer_mode !== "SELECT_WORDS" && newQuestion?.options?.length > 0 ?
            <>
              <div className="toggle-text-container">
                <div className="toggle-text-box">
                  <div><strong>Index:</strong></div>
                  {newQuestion.options.map((option, index: number) => {
                    return <div key={"text-" + index}>
                      {index}
                    </div>
                  })}
                </div>
                <div className="toggle-text-box">
                  <div><strong>Text:</strong></div>
                  {newQuestion.options.map((option, index: number) => {
                    return <div className={"toggle-text" + (selectedOptionIndex === index ? " selected" : "")} key={"text-" + index} onClick={() => toggleOption(index)}>{option.text}</div>
                  })}
                </div>
                <div className="toggle-text-box">
                  <div><strong>Type:</strong></div>
                  {newQuestion.options.map((option, index: number) => {
                    return <div key={"type-" + index}>{option.type}</div>
                  })}
                </div>
                {optionType !== "GRAPHIC" ? null :
                  <div className="toggle-text-box">
                    <div><strong>Graphic:</strong></div>
                    {newQuestion.options.map((option, index: number) => {
                      return <div key={"graphic-" + index}>{(option as GraphicOption).graphic}</div>
                    })}
                  </div>}
                <div className="toggle-text-box">
                  <div>Delete:</div>
                  {newQuestion.options.map((option, index: number) => {
                    return <div className="toggle-text" key={"delete-" + index}>
                      <span className="delete-item" onClick={() => deleteOption(index)}>delete</span>
                    </div>
                  })}
                </div>
                {newQuestion.answer_mode === "SELECT_WORDS" || newQuestion.answer_mode === "TYPE" ? null :
                <div className="toggle-text-box">
                  <div>Is it correct?</div>
                  {newQuestion.options.map((option, index: number) => {
                    return <div className="toggle-text" key={"delete-" + index}>
                      <span className="check-item" onClick={() => checkAsCorrect(index)}>check</span>
                    </div>
                  })}
                </div>}
              </div>
              {newQuestion.answer_mode === "SELECT_WORDS" ? <Button onClick={reshuffleOptions}>Shuffle options</Button> : null}
              <br />
            </> : null}

            {newQuestion.answer_mode === "SELECT_WORDS" && selectWords?.length > 0 ?
            <>
              <div className="toggle-text-container">
                <div className="toggle-text-box">
                  <div><strong>Text:</strong></div>
                  {selectWords.map((text, index: number) => {
                    return <div className={"toggle-text" + (selectedOptionIndex === index ? " selected" : "")} key={"text-" + index} onClick={() => toggleOption(index)}>{text}</div>
                  })}
                </div>
                <div className="toggle-text-box">
                  <div>Delete:</div>
                  {selectWords.map((text, index: number) => {
                    return <div className="toggle-text" key={"delete-" + index}>
                      <span className="delete-item" onClick={() => deleteSelectWordOption(index)}>delete</span>
                    </div>
                  })}
                </div>
              </div>
              <Button onClick={reshuffleOptions}>Shuffle options</Button>
              <br />
            </> : null}
        </Form>}
      <hr />
      {/* CORRECT ANSWERS */}
      {!newQuestion?.answer_mode || newQuestion?.answer_mode !== "TYPE" ? null :
        <Form onSubmit={addCorrectTextAnswer}>
          <h2>Correct answers:</h2>

          <Form.Field>
            {newQuestion?.answer_mode === "TYPE" ?
              <label>Enter correct answers</label>
              :
              <label>Enter correct answer index</label>
            }
          </Form.Field>
          <Form.Field className='flex-div'>
            <input
              type="text"
              name="correctAnswerText"
              value={correctAnswerText}
              onChange={(e) => setCorrectAnswerText(e.target.value)}
              placeholder={newQuestion?.answer_mode === "TYPE" ? "Enter correct answer as text" : "Enter the correct answer index"}
            />
          </Form.Field>
          <Form.Field>
            <Button disabled={!correctAnswerText}>Add correct answer</Button>
          </Form.Field>

          {/* {newQuestion?.correct_answers?.length > 0 ?
            <>
              <div className="toggle-text-container">
                <div className="toggle-text-box">
                  <div>Index:</div>
                  {newQuestion.correct_answers.map((text, index: number) => {
                    return <div className="toggle-text-box" key={"correct-answer-" + index}>{text}</div>
                  })}
                </div>
                <div className="toggle-text-box">
                  <div>Delete:</div>
                  {newQuestion.correct_answers.map((text, index: number) => {
                    return <div className="toggle-text-box" key={"delete-" + index}>
                      <span className="delete-item" onClick={() => deleteCorrectAnswer(index)}>delete</span>
                    </div>
                  })}
                </div>
              </div>
              <br />
            </> : null} */}
          <br />
          <hr />
          <br />
        </Form>}

      <section id="preview">
        <h2>Preview new question:</h2>
        <QuestionPreview question={newQuestion} />
      </section>
    </section>
  )
}

export default QuestionForm;