import { PiSpeakerSimpleHighDuotone } from "react-icons/pi";
import GraphicOption from "../../../../models/problem/answer/GraphicOption";
import IOption from "../../../../models/problem/answer/IOption";
import Question from "../../../../models/problem/question/Question";

function QuestionPreview({ question }: { question: Question }) {
  const bucketUrl = "https://lingoassets.s3.ap-northeast-2.amazonaws.com/";

  const getImageUrl = (imagePath: string) => {
    return bucketUrl + (imagePath.startsWith("/") || imagePath.startsWith("\\") ? imagePath.slice(1) : imagePath);
  };

  return (
    <div className="question-preview preview-col">
      { question ?
      <div className="question">
        <div className="title">{question.question_mode === "MATCH" ? "MATCH" : "Title"}</div>
        <hr/>
        <div className="question-chunks">
          {question.graphic ?
          <div className="image">
            {question.graphic ?
              <img alt='question.graphic' height={100} width={100} src={bucketUrl + (question.graphic.startsWith("/") || question.graphic.startsWith("\\") ? question.graphic.slice(1) :question.graphic)}/> : null
            }
          </div> : null}
          {question.question_mode === "LISTEN" ?
          <div><PiSpeakerSimpleHighDuotone size={20}/></div>
          :
          <div className="chunks">
            {question.question_chunks?.map(a => a.text).join(" ")}
            {question.question_chunks.filter(a => a.type === "TEXT_AUDIO")?.length > 0 ? <PiSpeakerSimpleHighDuotone size={20}/> : null}
          </div>
          }
        </div>
      </div> : null }
      { question ?
      <div className="answer">
        <hr/>
        {question.answer_mode === "CHOOSE_OPTION" ?
          <div className="options">
            {question.options?.map((option: IOption, oi) => {
              return <div className="option" key={'option' + oi}>
                <div className="option-text">{option.text}</div>
                <div className="option-audio">
                  {(option.type === "TEXT_AUDIO" || option.type === "AUDIO") ? <PiSpeakerSimpleHighDuotone size={20}/> : null}
                </div>
                <div className="option-image">
                  {(option as GraphicOption).graphic ? <img src={getImageUrl((option as GraphicOption).graphic)} height={40} width={"auto"} /> : null}
                </div>
                {String(oi) == question.correct_answers[0] ? "(Correct)": null}
              </div>
            })}
            <br/>
          </div> : 
          
          question.answer_mode === "SELECT_WORDS" ?
          <>
          <div className="options">
            {question.options?.map((option: IOption, oi) => {
              return <span className="select-word" key={'option' + oi}>{option.text}</span>
            })}
          </div>
          <div>
            <span>Correct answer: </span>
            {question.options ? question.correct_answers.map((answer: any, ai) => {
              return <span key={'correct-answer' + ai}>{question.options[Number(answer)]?.text} </span>
            }) : null}
          </div>
          </>
          : null
          }
          
          {question.answer_mode === "MATCH" ?
            <>
            <div className="options">
              LEFT: {question.options?.filter((e: any, idx) => idx % 2 === 0).map((option: IOption, oi) => {
                return <span className="select-word" key={'option' + oi}>{option.text}</span>
              })}
            </div>
            <div className="options">
              RIGHT: {question.options?.filter((e: any, idx) => idx % 2 === 1).map((option: IOption, oi) => {
                return <span className="select-word" key={'option' + oi}>{option.text}</span>
              })}
            </div>
            </>
            : null
          }

          {question.answer_mode === "TYPE" ?
            <div>
              <textarea></textarea>
              <p>Correct answer: {question.correct_answers[0]}</p>
            </div>
            : null
          }
        
        <button className="check-button">Check</button>
      </div> : null}
    </div>
  )
}

export default QuestionPreview;