import * as React from "react";
import { IoMailOpenOutline, IoWarningOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import styled from 'styled-components';

interface ChangePasswordProps {
}

interface ChangePasswordState {
  successMsg: string,
  errorMsg: string,
  newPassword: string,
  newPasswordMatch: string,
  loading: boolean
}

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    width: 350px;
    margin-top: 20px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
    box-sizing: border-box;
`;

// create a password schema
class AdminChangePasswordComponent extends React.Component<ChangePasswordProps, ChangePasswordState> {

  constructor(props: any) {
    super(props);

    this.state = {
      successMsg: "",
      errorMsg: "",
      newPassword: "",
      newPasswordMatch: "",
      loading: false
    };

    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordMatchChange = this.onPasswordMatchChange.bind(this)
  }

  onPasswordChange(e?: any, v?: any) {
    this.setState({ newPassword: e.target.value });
  }

  onPasswordMatchChange(e?: any, v?: any) {
    this.setState({ newPasswordMatch: e.target.value });
  }

  validatePassword() {
    return this.state.newPassword.length > 6;
  }

  validate(): boolean {
    if (!this.state.newPassword || !this.validatePassword()) {
      this.setState({ errorMsg: "Password must longer than 6 characters" })
      return false;
    }
    // if (!this.props.match.params.forgotKey) {
    //   this.setState({ errorMsg: "Линк алдаатай байна. Нууц үг сэргээх хүсэлт шинээр үүсгэнэ үү" })
    //   return false;
    // }
    return true;
  }

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    }

    this.setState({ errorMsg: "", loading: true })
    //TODO
    fetch(process.env.REACT_APP_CHEF_API + 'change-password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ forgotKey: "forgotKey", newPassword: this.state.newPassword })
    })
      .then(res => {
        console.log(res)
        if (!res.ok) {
          res.json().then(resJson => {
            this.setState({ errorMsg: resJson.errorMsg, loading: false })
          })
        } else {
          this.setState({ successMsg: "Your password has been changed successfully!", loading: false })
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ errorMsg: "Network error!", loading: false })
      })
  };

  scrollToForm = () => {
    let element = document.getElementById("signup-form");
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    return (
      <div className="page signup-page margin-top-50">
        <Section>
          <MyForm>
            <Form id="signup-form">
              { this.state.successMsg ?
                <React.Fragment>
                  <FormGroup>
                    <IoMailOpenOutline name="mail outline" color='green' />
                    {this.state.successMsg}
                  </FormGroup>
                  <FormGroup>
                    <Link to="/login">
                      <Button className="width-100" color='red'>Login</Button>
                    </Link>
                  </FormGroup>
                </React.Fragment>
                :
                <React.Fragment>
                  {this.state.errorMsg ? <React.Fragment>
                    <br />
                    <FormGroup>
                      <IoWarningOutline name="warning sign" color='red'/>
                      {this.state.errorMsg}
                    </FormGroup>
                  </React.Fragment> : null
                  }

                  <FormGroup>
                    <input type="password" required placeholder='New password' value={this.state.newPassword} onChange={this.onPasswordChange} />
                  </FormGroup>

                  <FormGroup>
                    <input type="password" required placeholder='Repeat new password' value={this.state.newPasswordMatch} onChange={this.onPasswordMatchChange} />
                  </FormGroup>

                  <br />
                  <Button className="width-100" color='red' onClick={this.handleSubmit}>Change password</Button>
                </React.Fragment>
              }
            </Form>
          </MyForm>
        </Section>
      </div>
    );
  }
}

export default AdminChangePasswordComponent;