import IStudyModel from "./IStudyModel";

export default class SectionModel implements IStudyModel {
    id: number;
    lang: string;
    index: number;
    color: string;
    name: string;
    description: string;
    version: string = "v0";
    active: boolean;

    public constructor(id: number, lang: string, index: number, color: string, name: string, description: string, active: boolean, version: string) {
        this.id = id;
        this.lang = lang;
        this.index = index;
        this.color = color;
        this.name = name;
        this.description = description;
        this.active = active;
        this.version = version;
    }
}