import Question from "../problem/question/Question";
import IStudyModel from "./IStudyModel";

export default class ExerciseModel implements IStudyModel {
    id: Number | undefined;
    lesson_id: number;
    index: number;
    name: string;
    description: string;
    version: string = "v0";
    active: boolean;
    questions: Question[] = [];

    public constructor(id: Number | undefined, lesson_id: number, index: number, name: string, description: string, active: boolean, version: string) {
        this.id = id;
        this.lesson_id = lesson_id;
        this.index = index;
        this.name = name;
        this.description = description;
        this.active = active;
        this.version = version;
        this.questions = [];
    }
}