import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminChangePasswordComponent from './components/authentication/ChangePassword';
import AdminForgotPasswordComponent from './components/authentication/ForgotPassword';
import AdminLoginComponent from './components/authentication/Login';
import ProtectedRoute from './components/authentication/ProtectedRoute';
import Dashboard from './components/dashboard/dashboard.component';
import ExerciseEditForm from './components/forms/study/exercise/ExerciseEditForm';
import ExerciseForm from './components/forms/study/ExerciseForm';
import LessonForm from './components/forms/study/LessonForm';
import SectionForm from './components/forms/study/SectionForm';
import UnitForm from './components/forms/study/UnitForm';
import AdminLayout from './components/layout/AdminLayout';
import ExercisesList from './components/lists/study/ExerciseList';
import LessonList from './components/lists/study/LessonList';
import SectionList from './components/lists/study/SectionList';
import UnitList from './components/lists/study/UnitList';
import UserLessonList from './components/lists/UserLessonList';
import UserList from './components/lists/UserList';
import './style/global.scss';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<AdminLoginComponent />} />
          <Route path="/forgot-password" element={<AdminForgotPasswordComponent />} />
          <Route path="/change-password" element={<AdminChangePasswordComponent />} />
          <Route path="/" element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
          >
            <Route index element={<Dashboard />} />
            <Route path="/sections" element={<SectionList />} />
            <Route path="/sections/new" element={<SectionForm />} />
            <Route path="/sections/:lang/:index" element={<SectionForm />} />
            <Route path="/units" element={<UnitList />} />
            <Route path="/units/new" element={<UnitForm />} />
            <Route path="/units/:section/:index" element={<UnitForm />} />
            <Route path="/lessons" element={<LessonList />} />
            <Route path="/lessons/new" element={<LessonForm />} />
            <Route path="/lessons/:unit/:index" element={<LessonForm />} />
            <Route path="/exercises" element={<ExercisesList />} />
            <Route path="/exercises/new" element={<ExerciseForm />} />
            <Route path="/exercises/:lesson/:index" element={<ExerciseEditForm />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/user-lessons" element={<UserLessonList />} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
