
export default class LocalStore {
  public static saveData = (url: string, data: any[]) => {
    //TODO save timestamp
    localStorage.setItem(url, JSON.stringify(data));
  };

  public static getData = (url: string): any | undefined => {
    let strValue = localStorage.getItem(url);
    //TODO check timestamp
    if (strValue) {
      return JSON.parse(strValue!);
    } else {
      return undefined
    }
  };
}
